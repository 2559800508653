import { L2ToL1MessageStatus as OutgoingMessageState } from '@arbitrum/sdk';
export { OutgoingMessageState };
export var TokenType;
(function (TokenType) {
    TokenType["ERC20"] = "ERC20";
    TokenType["ERC721"] = "ERC721";
})(TokenType || (TokenType = {}));
export var AssetType;
(function (AssetType) {
    AssetType["ERC20"] = "ERC20";
    AssetType["ERC721"] = "ERC721";
    AssetType["ETH"] = "ETH";
})(AssetType || (AssetType = {}));
