export const TOS_VERSION = 1

export function TermsOfService() {
  return (
    <div className="flex w-full justify-center">
      <div className="max-w-[1440px] px-8 py-8 text-white lg:px-32">
        <h2 className="mb-2 font-bold">ARBITRUM</h2>
        <h3 className="mb-2 font-semibold"> TERMS OF SERVICE</h3>
        <h5>
          <i>Date of Last Revision: March 7, 2022 </i>
        </h5>
        <br />
        <h3 className="mb-2 font-semibold">
          Acceptance of These Terms of Service
        </h3>
        <p className="mb-4">
          Offchain Labs, Inc. (“Offchain Labs,” “we,” “us,” or “our”) provides a
          website-hosted user interface (the “Interface”) for accessing
          Arbitrum, an optimistic rollup protocol on the Ethereum blockchain
          (the “Protocol”), and related content and functionality to you through
          our websites located at https://offchainlabs.com/,
          https://arbitrum.io/ and any other website operated by Offchain Labs
          on which these Terms of Service are posted (collectively, the “Site”)
          and through our related technologies (collectively, the Interface,
          Protocol and Site, including all existing and any updated or new
          features, functionality and technology, the “Service”). All access and
          use of the Service is subject to the terms and conditions contained in
          these Terms of Service (as amended from time to time, these “Terms of
          Service”). By accessing, browsing, or otherwise using the Site, the
          Interface, or any other aspect of the Service, you acknowledge that
          you have read, understood, and agree to be bound by these Terms of
          Service. If you are using the Service on behalf of an entity or other
          organization, you are agreeing to these Terms of Service for that
          entity or organization and representing to Offchain Labs that you have
          the authority to bind that entity or organization to these Terms of
          Service (and, in which case, the terms “you” and “your” will refer to
          that entity or organization). If you do not accept the terms and
          conditions of these Terms of Service, you will not access, browse or
          otherwise use the Service.
        </p>
        <p className="mb-4">
          We reserve the right, at our sole discretion, to change or modify
          portions of these Terms of Service at any time. If we do this, we will
          post the changes on this page and will indicate at the top of this
          page the date these Terms of Service were last revised. You may read a
          current, effective copy of these Terms of Service by visiting the
          “Terms of Service” link on the Site. Your continued use of the Service
          after the date any such changes become effective constitutes your
          acceptance of the new Terms of Service. You should periodically visit
          this page to review the current Terms of Service so you are aware of
          any revisions. If you do not agree to abide by these or any future
          Terms of Service, you will not access, browse, or use (or continue to
          access, browse, or use) the Service.
        </p>
        <p className="mb-4">
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN
          AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR
          LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
          REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
          AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL
          ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST OFFCHAIN LABS ON AN
          INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
          REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO
          SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF)
          ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS
          YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.
        </p>
        <p className="mb-4">
          <b>Your Privacy:</b> For more information regarding our collection,
          use and disclosure of personal data and certain other data, please see
          our Privacy Policy, located at
          https://www.iubenda.com/privacy-policy/76750372 (the “Privacy
          Policy”). By using the Service, you consent to our collection, use and
          disclosure of Personal Data and other data as outlined therein.
        </p>
        <p className="mb-4"></p>
        <p className="mb-4">
          <b>Additional Terms:</b> In addition, when using certain features
          through the Service, you will be subject to any additional terms
          applicable to such features that may be posted on or within the
          Service from time to time. All such terms are hereby incorporated by
          reference into these Terms of Service.
        </p>
        <h3 className="mb-2 font-semibold">Access and Use of the Service</h3>
        <p className="mb-4">
          <b>Legal Compliance:</b> You represent that your access and use of the
          Service will fully comply with all applicable laws and regulations,
          and that you will not access or use the Service to conduct, promote,
          or otherwise facilitate any illegal activity. You will comply with all
          applicable sanctions laws, regulations and rules, including but not
          limited to, those administered by the U.S. Department of the
          Treasury’s Office of Foreign Assets Control (“OFAC”), and any other
          applicable jurisdictions. The Interface may not be used: (i) in the
          Crimea region of Ukraine, Cuba, Iran, North Korea, or Syria; (ii) by
          or for the specific benefit of any individual or entity on the
          Specially Designated Nationals and Blocked Persons (“SDN”) List
          maintained by OFAC, (iii) any entity 50% or more owned in the
          aggregate by any such SDN(s); or (v) for any other use requiring a
          license or other governmental approval. If Offchain Labs determines
          that you have breached your obligation under this section, we shall
          block your access to the Service and any interests in property as
          required by law, if continued Service could result in Offchain Labs
          being in violation, or subject to negative consequences, under the
          Sanctions Rules.
        </p>
        <p className="mb-4">
          <b> Credentials:</b> You are responsible for maintaining the
          confidentiality of your credentials and are fully responsible for any
          and all activities that occur under your credentials.
        </p>
        <p className="mb-4">
          <b>Modifications to Service:</b> Offchain Labs reserves the right to
          modify or discontinue, temporarily or permanently, the Service (or any
          part thereof) with or without notice. You agree that Offchain Labs
          will not be liable to you or to any third party for any modification,
          suspension or discontinuance of the Service.
        </p>
        <p className="mb-4">
          <b>Your Apps</b> : As between Offchain Labs and you, you will be
          solely responsible for your applications (“Your Apps”), including
          their development, operation, maintenance and all related content and
          materials.
        </p>
        <h3 className="mb-2 font-semibold"> Conditions of Access and Use</h3>
        <p className="mb-4">
          <b>User Conduct:</b> You are solely responsible for all code, video,
          images, information, data, text, software, music, sound, photographs,
          graphics, messages, and other materials (“content”) that you make
          available to Offchain Labs, including by uploading, posting,
          publishing, or displaying (hereinafter, “upload(ing)”) via the Site or
          Your Apps or by emailing or otherwise making available to other users
          of the Site (collectively, “User Content”). The following are examples
          of the kinds of content and/or uses that are illegal or prohibited by
          Offchain Labs. Offchain Labs reserves the right to investigate and
          take appropriate legal action against anyone who, in Offchain Labs’
          sole discretion, violates this provision, including reporting the
          violator to law enforcement authorities. You agree to not use the
          Service or Your Apps to:
        </p>
        <p className="mb-4">
          <ol>
            <li>
              distribute any content that (i) infringes any intellectual
              property or other proprietary rights of any party; (ii) you do not
              have a right to upload under any law or under contractual or
              fiduciary relationships; (iii) contains software viruses or any
              other computer code, files or programs designed to interrupt,
              destroy, or limit the functionality of any computer software or
              hardware or telecommunications equipment; (iv) poses or creates a
              privacy or security risk to any person; (v) constitutes
              unsolicited or unauthorized advertising, promotional materials,
              commercial activities and/or sales, “junk mail,” “spam,” “chain
              letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any
              other form of solicitation; (vi) is unlawful, harmful,
              threatening, abusive, harassing, tortious, excessively violent,
              defamatory, vulgar, obscene, pornographic, libelous, invasive of
              another’s privacy, hateful, discriminatory, or otherwise
              objectionable; or (vii) in the sole judgment of Offchain Labs, is
              objectionable or which restricts or inhibits any other person from
              using or enjoying the Service, or which may expose Offchain Labs
              or its users to any harm or liability of any type;
            </li>
            <li>
              seek to interfere with or compromise the integrity, security, or
              proper functioning of any computer, server, network, personal
              device, or other information technology system, including the
              deployment of viruses and denial of service attacks;
            </li>
            <li>
              violate any applicable local, state, national, or international
              law, or any regulations having the force of law, including any
              laws or regulations concerning the integrity of trading markets
              (e.g., manipulative tactics commonly known as spoofing and wash
              trading) or trading of securities or derivatives;
            </li>
            <li>
              engage in any activity that seeks to defraud us or any other
              person or entity, including providing any false, inaccurate, or
              misleading information in order to unlawfully obtain the property
              of another;
            </li>
            <li>
              impersonate any person or entity, or falsely state or otherwise
              misrepresent your affiliation with a person or entity;
            </li>
            <li>
              solicit personal information from anyone under the age of 18;
            </li>
            <li>
              harvest or collect email addresses or other contact information of
              other users from the Service by electronic or other means for the
              purposes of sending unsolicited emails or other unsolicited
              communications; or
            </li>
            <li>
              further or promote any criminal activity or enterprise or provide
              instructional information about illegal activities.
            </li>
          </ol>
        </p>
        <p className="mb-4">
          If you are blocked by Offchain Labs from accessing the Service
          (including by blocking your IP address), you agree not to implement
          any measures to circumvent such blocking (e.g., by masking your IP
          address or using a proxy IP address or virtual private network).
        </p>
        <h3> No Professional Advice and No Fiduciary Duties</h3>
        <p className="mb-4">
          All information provided by the Service is for informational purposes
          only and should not be construed as professional advice. You should
          not take, or refrain from taking, any action based on any information
          contained in the Service. Before you make any financial, legal, or
          other decisions involving the Service, you should seek independent
          professional advice from an individual who is licensed and qualified
          in the area for which such advice would be appropriate.
        </p>
        <p className="mb-4">
          These Terms of Service are not intended to, and do not, create or
          impose any fiduciary duties on us. To the fullest extent permitted by
          law, you acknowledge and agree that we owe no fiduciary duties or
          liabilities to you or any other party, and that to the extent any such
          duties or liabilities may exist at law or in equity, those duties and
          liabilities are hereby irrevocably disclaimed, waived, and eliminated.
          You further agree that the only duties and obligations that we owe you
          are those set out expressly in these Terms of Service.
        </p>
        <h3 className="mb-2 font-semibold"> Intellectual Property Rights </h3>
        <p className="mb-4">
          <b>Service Content</b>: You acknowledge and agree that the Service may
          contain content or features (“Service Content”) that are protected by
          copyright, patent, trademark, trade secret, or other proprietary
          rights and laws. Except as expressly authorized by Offchain Labs
          (e.g., to the extent any of the Protocol is made available under an
          open source license), you agree not to modify, copy, frame, scrape,
          rent, lease, loan, sell, distribute, or create derivative works based
          on the Service or the Service Content, in whole or in part, except
          that the foregoing does not apply to your own User Content (as defined
          below) that you upload to or make available through the Service in
          accordance with these Terms of Service. Any use of the Service or the
          Service Content other than as specifically authorized herein is
          strictly prohibited.
        </p>
        <p className="mb-4">
          <b>Trademarks:</b> The Offchain Labs name and logos are trademarks and
          service marks of Offchain Labs (collectively the “Offchain Labs
          Trademarks”). Other company, product, and service names and logos used
          and displayed via the Service may be trademarks or service marks of
          their respective owners who may or may not endorse or be affiliated
          with or connected to Offchain Labs. Nothing in these Terms of Service
          or the Service should be construed as granting, by implication,
          estoppel, or otherwise, any license or right to use any of Offchain
          Labs Trademarks displayed on the Service, without our prior written
          permission in each instance. All goodwill generated from the use of
          Offchain Labs Trademarks will inure to our exclusive benefit.
        </p>
        <p className="mb-4">
          <b>Third-Party Material:</b>Under no circumstances will Offchain Labs
          be liable in any way for any content or materials of any third parties
          (including users), including for any errors or omissions in any
          content, or for any loss or damage of any kind incurred as a result of
          the use of any such content. You acknowledge that Offchain Labs does
          not pre-screen content, but that Offchain Labs and its designees will
          have the right (but not the obligation) in their sole discretion to
          refuse or remove any content that is available via the Site. Without
          limiting the foregoing, Offchain Labs and its designees will have the
          right to remove from the Site any content that violates these Terms of
          Service or is deemed by Offchain Labs, in its sole discretion, to be
          otherwise objectionable. You agree that you must evaluate, and bear
          all risks associated with, the use of any content, including any
          reliance on the accuracy, completeness, or usefulness of such content.
        </p>
        <p className="mb-4">
          <b> User Content:</b>You represent and warrant that you own all right,
          title and interest in and to such User Content that you make available
          via the Site, including all copyrights and rights of publicity
          contained therein.
        </p>
        <p className="mb-4">
          You hereby grant Offchain Labs and its affiliated companies,
          successors, and assigns a non-exclusive, worldwide, royalty-free,
          fully paid-up, transferable, sublicensable (directly and indirectly
          through multiple tiers), perpetual, and irrevocable license to copy,
          display, upload, perform, distribute, store, modify, and otherwise use
          such User Content in connection with the operation of the Site. You
          assume all risk associated with your User Content and the transmission
          of your User Content, and you have sole responsibility for the
          accuracy, quality, legality and appropriateness of your User Content.
          You hereby authorize Offchain Labs and its third-party service
          providers to derive statistical and usage data relating to your use of
          the Site (“Usage Data”). We may use Usage Data for any purpose in
          accordance with applicable law and our Privacy Policy.
        </p>
        <p className="mb-4">
          Any questions, comments, suggestions, ideas, feedback, reviews, or
          other information about the Service (“Submissions”), provided by you
          to Offchain Labs are non-confidential and Offchain Labs will be
          entitled to the unrestricted use and dissemination of these
          Submissions for any purpose, commercial or otherwise, without
          acknowledgment, attribution, or compensation to you.
        </p>
        <p className="mb-4">
          You acknowledge and agree that Offchain Labs may preserve User Content
          and may also disclose User Content if required to do so by law or in
          the good faith belief that such preservation or disclosure is
          reasonably necessary to: (a) comply with legal process, applicable
          laws, or government requests; (b) enforce these Terms of Service; (c)
          respond to claims that any content violates the rights of third
          parties; or (d) protect the rights, property, or personal safety of
          Offchain Labs, its users, or the public. You understand that the
          technical processing and transmission of the Service, including your
          User Content, may involve (i) transmissions over various networks; and
          (ii) changes to conform and adapt to technical requirements of
          connecting networks or devices.
        </p>
        <h3 className="mb-2 font-semibold">Third-Party Services</h3>
        <p className="mb-4">
          The Service provides access to services, sites, technology,
          applications and resources that are provided or otherwise made
          available by third parties (the “Third-Party Services”). Your access
          and use of the Third-Party Services may also be subject to additional
          terms and conditions, privacy policies, or other agreements with such
          third party. Offchain Labs has no control over and is not responsible
          for such Third-Party Services, including for the accuracy,
          availability, reliability, or completeness of information shared by or
          available through Third-Party Services, or on the privacy practices of
          Third-Party Services. We encourage you to review the privacy policies
          of the third parties providing Third-Party Services prior to using
          such services. You, and not Offchain Labs, will be responsible for any
          and all costs and charges associated with your use of any Third-Party
          Services. The integration or inclusion of such Third-Party Services
          does not imply an endorsement or recommendation. Any dealings you have
          with third parties while using the Service are between you and the
          third party. Offchain Labs will not be responsible or liable, directly
          or indirectly, for any damage or loss caused or alleged to be caused
          by or in connection with use of or reliance on any Third-Party
          Services.
        </p>
        <h3 className="mb-2 font-semibold">Indemnification and Release</h3>
        <p className="mb-4">
          You agree to defend, indemnify, and hold harmless Offchain Labs, its
          affiliates, and its and their respective officers, employees,
          directors, service providers, licensors, and agents (collectively, the
          “Offchain Labs Parties”) from any and all losses, damages, expenses,
          including reasonable attorneys’ fees, rights, claims, actions of any
          kind, and injury (including death) arising out of or relating to your
          use of the Service, Your Apps, any User Content, your connection to
          the Service, your violation of these Terms of Service, or your
          violation of any rights of another. Offchain Labs will provide notice
          to you of any such claim, suit, or proceeding. Offchain Labs reserves
          the right to assume the exclusive defense and control of any matter
          which is subject to indemnification under this section, and you agree
          to cooperate with any reasonable requests assisting Offchain Labs’
          defense of such matter. You may not settle or compromise any claim
          against the Offchain Labs Parties without Offchain Labs’ written
          consent.
        </p>
        <p className="mb-4">
          You expressly agree that you assume all risks in connection with your
          access and use of the Service, including your interaction with the
          Protocol. You further expressly waive and release us from any and all
          liability, claims, causes of action, or damages arising from or in any
          way relating to your use of the Service, including your interaction
          with the Protocol. If you are a California resident, you waive
          California Civil Code Section 1542, which says: “A general release
          does not extend to claims that the creditor or releasing party does
          not know or suspect to exist in his or her favor at the time of
          executing the release and that, if known by him or her, would have
          materially affected his or her settlement with the debtor or releasing
          party.” If you are a resident of another jurisdiction, you waive any
          comparable statute or doctrine.
        </p>
        <h3 className="mb-2 font-semibold">
          Disclaimer of Warranties; Assumption of Risk
        </h3>
        <p className="mb-4">
          YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED
          ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE OFFCHAIN LABS PARTIES
          EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
          IMPLIED OR STATUTORY, INCLUDING THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
          NON-INFRINGEMENT.
        </p>
        <p className="mb-4">
          THE OFFCHAIN LABS PARTIES MAKE NO WARRANTY THAT (A) THE SERVICE WILL
          MEET YOUR REQUIREMENTS; (B) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
          SECURE, OR ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE
          USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE; OR (D) THE QUALITY OF
          ANY PRODUCTS, SERVICES, APPLICATIONS, INFORMATION, OR OTHER MATERIAL
          PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR
          EXPECTATIONS.
        </p>
        <p className="mb-4">
          BY ACCESSING AND USING THE SERVICE, YOU REPRESENT AND WARRANT THAT YOU
          UNDERSTAND THE INHERENT RISKS ASSOCIATED WITH USING CRYPTOGRAPHIC AND
          BLOCKCHAIN-BASED SYSTEMS, AND THAT YOU HAVE A WORKING KNOWLEDGE OF THE
          USAGE AND INTRICACIES OF DIGITAL ASSETS, SUCH AS THOSE FOLLOWING THE
          ETHEREUM TOKEN STANDARD (ERC-20) AND BRIDGING ACROSS DIFFERENT
          BLOCKCHAIN SOLUTIONS. YOU FURTHER UNDERSTAND THAT THE MARKETS FOR
          THESE DIGITAL ASSETS ARE HIGHLY VOLATILE DUE TO VARIOUS FACTORS,
          INCLUDING ADOPTION, SPECULATION, TECHNOLOGY, SECURITY, AND REGULATION.
          YOU ACKNOWLEDGE AND ACCEPT THAT THE COST AND SPEED OF TRANSACTING WITH
          CRYPTOGRAPHIC AND BLOCKCHAIN-BASED SYSTEMS SUCH AS ARBITRUM AND
          ETHEREUM ARE VARIABLE AND MAY INCREASE DRAMATICALLY AT ANY TIME. YOU
          FURTHER ACKNOWLEDGE AND ACCEPT THE RISK THAT YOUR DIGITAL ASSETS MAY
          LOSE SOME OR ALL OF THEIR VALUE WHILE THEY ARE SUPPLIED TO THE
          PROTOCOL THROUGH THE INTERFACE, YOU MAY SUFFER LOSS DUE TO THE
          FLUCTUATION OF PRICES OF TOKENS IN A TRADING PAIR OR LIQUIDITY POOL,
          AND, ESPECIALLY IN EXPERT MODES, EXPERIENCE SIGNIFICANT PRICE SLIPPAGE
          AND COST. YOU UNDERSTAND THAT ANYONE CAN CREATE A TOKEN, INCLUDING
          FAKE VERSIONS OF EXISTING TOKENS AND TOKENS THAT FALSELY CLAIM TO
          REPRESENT PROJECTS, AND ACKNOWLEDGE AND ACCEPT THE RISK THAT YOU MAY
          MISTAKENLY TRADE THOSE OR OTHER TOKENS. YOU FURTHER ACKNOWLEDGE THAT
          WE ARE NOT RESPONSIBLE FOR ANY OF THESE VARIABLES OR RISKS, AND CANNOT
          BE HELD LIABLE FOR ANY RESULTING LOSSES THAT YOU EXPERIENCE WHILE
          ACCESSING OR USING THE SERVICE. ACCORDINGLY, YOU UNDERSTAND AND AGREE
          TO ASSUME FULL RESPONSIBILITY FOR ALL OF THE RISKS OF ACCESSING AND
          USING THE SERVICE, INCLUDING THE INTERFACE TO INTERACT WITH THE
          PROTOCOL.
        </p>
        <h3 className="mb-2 font-semibold">Limitation of Liability</h3>
        <p className="mb-4">
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE OFFCHAIN LABS PARTIES WILL
          NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
          EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING DAMAGES
          FOR LOSS OF GOODWILL, USE, OR DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
          THE OFFCHAIN LABS PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
          LIABILITY, OR OTHERWISE, RESULTING FROM: (A) THE USE OR THE INABILITY
          TO USE THE SERVICE; (B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS
          AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES
          PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED
          INTO THROUGH OR FROM THE SERVICE; (C) UNAUTHORIZED ACCESS TO OR
          ALTERATION OF YOUR TRANSMISSIONS OR DATA; (D) STATEMENTS OR CONDUCT OF
          ANY THIRD PARTY ON THE SERVICE; (E) INTERRUPTION OR CESSATION OF
          FUNCTION RELATED TO THE INTERFACE; (F) BUGS, VIRUSES, TROJAN HORSES,
          OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE INTERFACE; (G)
          ERRORS OR OMISSIONS IN, OR LOSS OR DAMAGE INCURRED AS A RESULT OF THE
          USE OF, ANY CONTENT MADE AVAILABLE THROUGH THE INTERFACE; OR (H) ANY
          OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL THE OFFCHAIN
          LABS PARTIES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR
          CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID OFFCHAIN LABS IN THE
          LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100).{' '}
        </p>
        <p className="mb-4">
          SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN
          WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL
          OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS
          SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO
          YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH
          THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
          DISCONTINUE USE OF THE SERVICE.{' '}
        </p>
        <p className="mb-4">
          IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED
          “DISCLAIMER OF WARRANTIES; ASSUMPTION OF RISK” AND “LIMITATION OF
          LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE
          LAWS OF THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS
          HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE
          INVALIDITY OF SUCH PORTION WILL NOT AFFECT THE VALIDITY OF THE
          REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
        </p>
        <h3 className="mb-2 font-semibold">
          Dispute Resolution By Binding Arbitration
        </h3>
        <h3 className="mb-2 font-semibold">
          PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
        </h3>
        <h5>a. Agreement to Arbitrate </h5>
        <p className="mb-4">
          This Dispute Resolution by Binding Arbitration section is referred to
          in these Terms of Service as the “Arbitration Agreement.” You agree
          that any and all disputes or claims that have arisen or may arise
          between you and Offchain Labs, whether arising out of or relating to
          these Terms of Service (including any alleged breach thereof), the
          Service, any advertising, or any aspect of the relationship or
          transactions between us, will be resolved exclusively through final
          and binding arbitration, rather than a court, in accordance with the
          terms of this Arbitration Agreement, except that you may assert
          individual claims in small claims court, if your claims qualify.
          Further, this Arbitration Agreement does not preclude you from
          bringing issues to the attention of federal, state, or local agencies,
          and such agencies can, if the law allows, seek relief against us on
          your behalf. You agree that, by entering into these Terms of Service,
          you and Offchain Labs are each waiving the right to a trial by jury or
          to participate in a class action. Your rights will be determined by a
          neutral arbitrator, not a judge or jury. The Federal Arbitration Act
          governs the interpretation and enforcement of this Arbitration
          Agreement.
        </p>
        <h5>
          b. Prohibition of Class and Representative Actions and
          Non-Individualized Relief
        </h5>
        <p className="mb-4">
          YOU AND OFFCHAIN LABS AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST
          THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
          UNLESS BOTH YOU AND OFFCHAIN LABS AGREE OTHERWISE, THE ARBITRATOR MAY
          NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND
          MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
          REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD
          RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY
          IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
          NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL
          CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR
          MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT
          REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.{' '}
        </p>
        <h5>c. Pre-Arbitration Dispute Resolution</h5>
        <p className="mb-4">
          Offchain Labs is always interested in resolving disputes amicably and
          efficiently, and most customer concerns can be resolved quickly and to
          the customer’s satisfaction by emailing customer support at
          info@offchainlabs.com. If such efforts prove unsuccessful, a party who
          intends to seek arbitration must first send to the other, by certified
          mail, a written Notice of Dispute (“Notice”). The Notice to Offchain
          Labs should be sent to 252 Nassau Street, Floor 2, Princeton, New
          Jersey 08542 (“Notice Address”). The Notice must (i) describe the
          nature and basis of the claim or dispute and (ii) set forth the
          specific relief sought. If Offchain Labs and you do not resolve the
          claim within sixty (60) calendar days after the Notice is received,
          you or Offchain Labs may commence an arbitration proceeding. During
          the arbitration, the amount of any settlement offer made by Offchain
          Labs or you will not be disclosed to the arbitrator until after the
          arbitrator determines the amount, if any, to which you or Offchain
          Labs is entitled.
        </p>
        <h5>d. Arbitration Procedures</h5>
        <p className="mb-4">
          Arbitration will be conducted by a neutral arbitrator in accordance
          with the American Arbitration Association’s (“AAA”) rules and
          procedures, including the AAA’s Consumer Arbitration Rules
          (collectively, the “AAA Rules”), as modified by this Arbitration
          Agreement. For information on the AAA, please visit its website,
          http://www.adr.org. Information about the AAA Rules and fees for
          consumer disputes can be found at the AAA’s consumer arbitration page,
          https://www.adr.org/consumer. If there is any inconsistency between
          any term of the AAA Rules and any term of this Arbitration Agreement,
          the applicable terms of this Arbitration Agreement will control unless
          the arbitrator determines that the application of the inconsistent
          Arbitration Agreement terms would not result in a fundamentally fair
          arbitration. The arbitrator must also follow the provisions of these
          Terms of Service as a court would. All issues are for the arbitrator
          to decide, including issues relating to the scope, enforceability, and
          arbitrability of this Arbitration Agreement. Although arbitration
          proceedings are usually simpler and more streamlined than trials and
          other judicial proceedings, the arbitrator can award the same damages
          and relief on an individual basis that a court can award to an
          individual under these Terms of Service and applicable law. Decisions
          by the arbitrator are enforceable in court and may be overturned by a
          court only for very limited reasons.
        </p>
        <p className="mb-4">
          Unless Offchain Labs and you agree otherwise, any arbitration hearings
          will take place in a reasonably convenient location for both parties
          with due consideration of their ability to travel and other pertinent
          circumstances. If the parties are unable to agree on a location, the
          determination will be made by AAA. If your claim is for $10,000 or
          less, Offchain Labs agrees that you may choose whether the arbitration
          will be conducted solely on the basis of documents submitted to the
          arbitrator, through a telephonic hearing, or by an in-person hearing
          as established by the AAA Rules. If your claim exceeds $10,000, the
          right to a hearing will be determined by the AAA Rules. Regardless of
          the manner in which the arbitration is conducted, the arbitrator will
          issue a reasoned written decision sufficient to explain the essential
          findings and conclusions on which the award is based.
        </p>
        <h5>e. Costs of Arbitration</h5>
        <p className="mb-4">
          Payment of all filing, administration, and arbitrator fees
          (collectively, the “Arbitration Fees”) will be governed by the AAA
          Rules, unless otherwise provided in this Arbitration Agreement. If the
          value of the relief sought is $75,000 or less, at your request,
          Offchain Labs will pay all Arbitration Fees. If the value of relief
          sought is more than $75,000 and you are able to demonstrate to the
          arbitrator that you are economically unable to pay your portion of the
          Arbitration Fees or if the arbitrator otherwise determines for any
          reason that you should not be required to pay your portion of the
          Arbitration Fees, Offchain Labs will pay your portion of such fees. In
          addition, if you demonstrate to the arbitrator that the costs of
          arbitration will be prohibitive as compared to the costs of
          litigation, Offchain Labs will pay as much of the Arbitration Fees as
          the arbitrator deems necessary to prevent the arbitration from being
          cost-prohibitive. Any payment of attorneys’ fees will be governed by
          the AAA Rules.
        </p>
        <h5>f. Confidentiality</h5>
        <p className="mb-4">
          All aspects of the arbitration proceeding, and any ruling, decision,
          or award by the arbitrator, will be strictly confidential for the
          benefit of all parties.
        </p>
        <h5>g. Severability</h5>
        <p className="mb-4">
          If a court or the arbitrator decides that any term or provision of
          this Arbitration Agreement (other than the subsection (b) above titled
          “Prohibition of Class and Representative Actions and
          Non-Individualized Relief” above) is invalid or unenforceable, the
          parties agree to replace such term or provision with a term or
          provision that is valid and enforceable and that comes closest to
          expressing the intention of the invalid or unenforceable term or
          provision, and this Arbitration Agreement will be enforceable as so
          modified. If a court or the arbitrator decides that any of the
          provisions of subsection (b) above titled “Prohibition of Class and
          Representative Actions and Non-Individualized Relief” are invalid or
          unenforceable, then the entirety of this Arbitration Agreement will be
          null and void, unless such provisions are deemed to be invalid or
          unenforceable solely with respect to claims for public injunctive
          relief. The remainder of these Terms of Service will continue to
          apply.
        </p>
        <h5>h. Future Changes to Arbitration Agreement</h5>
        <p className="mb-4">
          Notwithstanding any provision in these Terms of Service to the
          contrary, Offchain Labs agrees that if it makes any future change to
          this Arbitration Agreement (other than a change to the Notice Address)
          while you are a user of the Service, you may reject any such change by
          sending Offchain Labs written notice within thirty (30) calendar days
          of the change to the Notice Address provided above. By rejecting any
          future change, you are agreeing that you will arbitrate any dispute
          between us in accordance with the language of this Arbitration
          Agreement as of the date you first accepted these Terms of Service (or
          accepted any subsequent changes to these Terms of Service).
        </p>
        <h3 className="mb-2 font-semibold">User Disputes</h3>
        <p className="mb-4">
          You agree that you are solely responsible for your interactions with
          any other user in connection with the Service, and Offchain Labs will
          have no liability or responsibility with respect thereto. Offchain
          Labs reserves the right, but has no obligation, to become involved in
          any way with disputes between you and any other user of the Service.
        </p>
        <h3 className="mb-2 font-semibold">General</h3>
        <p className="mb-4">
          These Terms of Service (together with the terms incorporated by
          reference herein) constitute the entire agreement between you and
          Offchain Labs governing your access and use of the Service, and
          supersede any prior agreements between you and Offchain Labs with
          respect to the Service. You also may be subject to additional terms
          and conditions that may apply when you use Third-Party Services,
          third-party content or third-party software. These Terms of Service
          will be governed by the laws of the State of Delaware without regard
          to its conflict of law provisions. With respect to any disputes or
          claims not subject to arbitration, as set forth above, you and
          Offchain Labs submit to the personal and exclusive jurisdiction of the
          state and federal courts located within New York, New York. The
          failure of Offchain Labs to exercise or enforce any right or provision
          of these Terms of Service will not constitute a waiver of such right
          or provision. If any provision of these Terms of Service is found by a
          court of competent jurisdiction to be invalid, the parties
          nevertheless agree that the court should endeavor to give effect to
          the parties’ intentions as reflected in the provision, and the other
          provisions of these Terms of Service remain in full force and effect.
          You agree that regardless of any statute or law to the contrary, any
          claim or cause of action arising out of or related to use of the
          Service or these Terms of Service must be filed within one (1) year
          after such claim or cause of action arose or be forever barred. A
          printed version of these Terms of Service and of any notice given in
          electronic form will be admissible in judicial or administrative
          proceedings based upon or relating to these Terms of Service to the
          same extent and subject to the same conditions as other business
          documents and records originally generated and maintained in printed
          form. You may not assign these Terms of Service without the prior
          written consent of Offchain Labs, but Offchain Labs may assign or
          transfer these Terms of Service, in whole or in part, without
          restriction. The section titles in these Terms of Service are for
          convenience only and have no legal or contractual effect. As used in
          these Terms of Service, the words “include” and “including,” and
          variations thereof, will not be deemed to be terms of limitation, but
          rather will be deemed to be followed by the words “without
          limitation.” Notices to you may be made via either email or regular
          mail. The Service may also provide notices to you of changes to these
          Terms of Service or other matters by displaying notices or links to
          notices generally on the Service. Offchain Labs will not be in default
          hereunder by reason of any failure or delay in the performance of its
          obligations where such failure or delay is due to civil disturbances,
          riot, epidemic, hostilities, war, terrorist attack, embargo, natural
          disaster, acts of God, flood, fire, sabotage, fluctuations or
          unavailability of electrical power, network access or equipment, or
          any other circumstances or causes beyond Offchain Labs’ reasonable
          control.
        </p>
        <h3 className="mb-2 font-semibold">
          U.S. Government Restricted Rights
        </h3>
        <p className="mb-4">
          The Service is made available to the U.S. government with “RESTRICTED
          RIGHTS.” Use, duplication, or disclosure by the U.S. government is
          subject to the restrictions contained in 48 CFR 52.227-19 and 48 CFR
          252.227-7013 et seq. or its successor. Access or use of the Service
          (including the Software) by the U.S. government constitutes
          acknowledgement of our proprietary rights in the Service (including
          the Software).
        </p>
        <h3 className="mb-2 font-semibold">
          Questions? Concerns? Suggestions?
        </h3>
        <p className="mb-4">
          Please contact us at info@offchainlabs.com to report any violations of
          these Terms of Service or to pose any questions regarding these Terms
          of Service or the Service.
        </p>
      </div>
    </div>
  )
}
